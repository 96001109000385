<template>
    <div>
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-3">
                <div class="row">
                    <div class="d-none d-xl-inline-block col-xl-12 col-xxl-12">
                        <div class="overflow-y-hidden overflow-x-hidden w-100 d-none d-lg-block position-sticky" style="height: 400px;">
                            <b-button
                                v-for="(itm, key) in btnArea" :key="key"
                                variant="block justify-content-start h-auto font-weight-medium line-height-normal py-2 text-left"
                                :class="[selectedButton === itm ? 'btn-outline-primary' : 'secondary-border-lighter btn-outline-secondary']"
                                @click="goToDetail(itm)">
                                {{itm.text}}
                            </b-button>
                        </div>
                    </div>
                    <div class="col-12">
                        <b-form-group label="" class="d-block d-xl-none pb-2 pb-xl-0">
                            <template>
                                <div>
                                    <multiselect
                                        v-model="selectedButton"
                                        :options="btnArea"
                                        @input="goToDetail"
                                        label="text"
                                        track-by="value"
                                        :multiple="false"
                                        :select-label="''"
                                        :selected-label="''"
                                        :deselect-label="''"
                                        :placeholder="$t('select')"
                                        :searchable="true">
                                <span slot="noOptions">
                                    {{ $t('no_options') }}
                                </span>
                                    </multiselect>
                                </div>
                            </template>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9">
                <div class="border rounded p-5">
                    <div class="row d-flex justify-content-between">
                        <div class="col-11">
                            <personal-info
                                v-if="selectedButton.value == 1"
                                :nationalId="studentId"></personal-info>
                            <student-info
                                v-else-if="selectedButton.value == 2"
                                :nationalId="studentId"></student-info>
                            <graduate-info
                                v-else-if="selectedButton.value == 3"
                                :nationalId="studentId"></graduate-info>
                            <kyk-info
                                v-else-if="selectedButton.value == 4"
                                :nationalId="studentId"></kyk-info>
                            <asal-info
                                v-else-if="selectedButton.value == 5"
                                :nationalId="studentId"></asal-info>
                            <prep-info
                                v-else-if="selectedButton.value == 9"
                                :nationalId="studentId"></prep-info>
                            <meb-info
                                v-else-if="selectedButton.value == 6"
                                :nationalId="studentId"></meb-info>
                            <equivalence-info
                                v-else-if="selectedButton.value == 7"
                                :nationalId="studentId"></equivalence-info>
                            <send-info
                                v-else-if="selectedButton.value == 8"
                                :nationalId="studentId">
                            </send-info>
                        </div>
                        <b-button @click="goBack" variant="outline-primary"><i class="ri-arrow-go-back-line"></i></b-button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
//Template
//Component
//Other
//Service
//Page
import PersonalInfo from "./detailTabs/PersonalInfo";
import StudentInfo from "./detailTabs/StudentInfo";
import GraduateInfo from "./detailTabs/GraduateInfo";
import KykInfo from "./detailTabs/KykInfo";
import AsalInfo from "./detailTabs/AsalInfo";
import MebInfo from "./detailTabs/MebInfo";
import EquivalenceInfo from "./detailTabs/EquivalenceInfo";
import SendInfo from "./detailTabs/SendInfo";
import PrepInfo from "@/modules/yoksisOperations/pages/detailTabs/PrepInfo.vue";
export default {
    props: {
        studentId: {
            type: String,
            default: null
        }
    },
    components: {
        PrepInfo,
        PersonalInfo,
        StudentInfo,
        GraduateInfo,
        KykInfo,
        AsalInfo,
        MebInfo,
        EquivalenceInfo,
        SendInfo,

    },
    metaInfo() {
        return {
            title: this.$t("yoksis_operations"),
        };
    },
    data() {
        return {
            btnArea: [
                {
                    text: this.$t('personal_info'),
                    value: 1
                },
                {
                    text: this.$t('student_information'),
                    value: 2
                },
                {
                    text: this.$t('graduate_information'),
                    value: 3
                },
                {
                    text: this.$t('kyk_information'),
                    value: 4
                },
                {
                    text: this.$t('asal_information'),
                    value: 5
                },
                {
                    text: this.$t('prep_information'),
                    value: 9
                },
                {
                    text: this.$t('meb_information'),
                    value: 6
                },
                {
                    text: this.$t('equivalence_information'),
                    value: 7
                },
                {
                    text: this.$t('yoksis_send_information'),
                    value: 8
                },


            ],

            selectedButton: {
                text: this.$t('personal_info'),
                value: 1
            },
            msgOptions: [

            ],
        }
    },
    methods: {
        goBack() {
            this.$emit('goBack', true)
        },

        goToDetail(tab){
            this.selectedButton = tab
        },
    },

    created(){

    }
};
</script>


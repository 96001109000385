<template>
    <div>
        <ValidationObserver ref="validForm">
            <b-row class="mt-4">
                <b-col cols="12" md="6">
                    <ValidationProvider name="student_status" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('student_status')">
                            <parameter-selectbox
                                :multiple="true"
                                code="student_statuses"
                                 v-model="formData.student_status"
                                :validateError="errors[0]"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="faculty_code" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('faculty_name')">
                            <faculty-selectbox
                                :multiple="true"
                                v-model="formData.faculty_code"
                                :validateError="errors[0]"></faculty-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col cols="12" md="2">
                    <b-button @click="excelExport" block variant="success">{{$t('excel')}}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
//Template
//Component
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
//Other
//Service
import YoksisService from "@/services/YoksisService";
import qs from "qs";
//Page


export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        ParameterSelectbox,
        FacultySelectbox
    },
    metaInfo() {
        return {
            title: this.$t("yoksis_operations"),
        };
    },
    data() {
        return {
            formData: {
                student_status: null,
                faculty_code: null
            }
        }
    },
    methods: {
        async excelExport(){
            if(this.checkPermission('yoksisservice_studentsearchexcelexport')){
                const isValid = await this.$refs.validForm.validate();
                if (isValid) {
                    let config = {
                        params: {
                            student_status: this.formData.student_status,
                            faculty_code: this.formData.faculty_code
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false})
                    }
                    YoksisService.studentSearchExcelExport(config).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(e => {
                        this.showErrors(e, this.$refs.validForm)
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        }
    },
};
</script>


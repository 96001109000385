<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('prep_information').toUpper() }}</h5>
        <div v-for="(itm,key) in data" :key="key">
            <div class="mb-2 d-flex">
                <b-button variant="light"
                          class="border w-40 h-40 mr-2"
                          v-b-toggle="'collapse-'+key">
                    <i class="ri-arrow-down-s-line"></i>
                </b-button>
                <div
                    class="flex-grow-1 bg-light w-40 h-40 border rounded-sm d-flex align-items-center pl-3 font-weight-bold">
                    {{ itm.id }}
                </div>
            </div>
            <b-collapse :id="'collapse-'+key">
                <div class="border rounded-sm p-4 mb-4">
                    <b-row>
                        <b-col cols="auto">
                            <b-form-group :label="$t('unit_id')">
                                <div class="label-as-input">
                                    {{itm.unit_id}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('prep_type')">
                                <div class="label-as-input">
                                    {{itm.prep_type}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('education_language')">
                                <div class="label-as-input">
                                    {{itm.education_language}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('success_status')">
                                <div class="label-as-input">
                                    {{itm.success_status}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('exemption_status')">
                                <div class="label-as-input">
                                    {{itm.exemption_status}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('prep_note')">
                                <div class="label-as-input">
                                    {{itm.prep_note}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('finish_date')">
                                <div class="label-as-input">
                                    {{itm.finish_date}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('semester_count')">
                                <div class="label-as-input">
                                    {{itm.semester_count}}
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-collapse>
        </div>
    </div>
</template>
<script>
//Template
//Component
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    props: {
        nationalId: {
            type: String,
            default: null
        }
    },
    components: {

    },
    data() {
        return {
            data: {}
        }
    },
    methods: {
        getData(nationalId){
            YoksisService.prepInformation(nationalId).then(response => {
                this.data = response.data.data
            }).catch(e => {
                this.$toast.error(e.data.message)
            })
        }
    },

    created(){
        this.getData(this.nationalId)
    }
};
</script>


<template>
    <div>
        <ValidationObserver ref="validForm">
            <b-row class="mt-4">
                <b-col cols="12" md="4">
                    <ValidationProvider name="national_number" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('national_number')">
                            <b-form-input
                                v-mask="'###########'"
                                v-model="nationalNumber"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row class="mt-2 mb-4">
                <b-col cols="12" md="2">
                    <b-button @click="searchStudent" block variant="primary">{{$t('questioning_do')}}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
//Template
//Component
import {ValidationObserver, ValidationProvider} from "vee-validate";
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    components: {
        ValidationObserver,
        ValidationProvider
    },
    metaInfo() {
        return {
            title: this.$t("yoksis_student_questioning"),
        };
    },
    data() {
        return {
            nationalNumber: null,

        }
    },
    methods: {
        showDetail(id){
            this.$emit('showStudentInfoDetail', id)
        },

        async searchStudent(){
            if(this.checkPermission('yoksisservice_educationinformation')){
                const isValid = await this.$refs.validForm.validate();
                if (isValid) {
                    YoksisService.educationInformation(this.nationalNumber).then(response => {
                        this.showDetail(this.nationalNumber)
                    }).catch(e => {
                        this.$toast.error(e.data.message)
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        }
    }
};
</script>


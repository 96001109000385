<template>
    <div>
        <ValidationObserver ref="validForm">
            <b-row class="mt-4">
                <b-col cols="12" md="6">
                    <ValidationProvider name="reportType" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('yoksis_report_type')">
                            <multi-selectbox
                                v-model="reportType"
                                :multiple="false"
                                :options="reportOptions"
                                :validateError="errors[0]"></multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="4" v-if="![1,6].includes(reportType)">
                    <ValidationProvider name="nationalNumber" :rules="[4].includes(reportType) ? 'required' : ''" v-slot="{valid, errors}">
                        <b-form-group :label="$t('national_number')">
                            <b-form-input
                                v-mask="'###########'"
                                v-model="nationalNumber"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" v-if="[1].includes(reportType)">
                    <ValidationProvider name="student_statuses" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('student_status')">
                            <parameter-selectbox
                                :multiple="true"
                                code="student_statuses"
                                v-model="studentStatus"
                                :validateError="errors[0]"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" v-if="[6].includes(reportType)">
                    <ValidationProvider name="just_active" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('just_active')" class="mb-4">
                        <b-form-radio-group
                            id="radio-group-2"
                            v-model="justActive"
                            name="radio-sub-component"
                            stacked>
                            <b-form-radio value="1">{{ $t('yes') }}</b-form-radio>
                            <b-form-radio value="0">{{ $t('no') }}</b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row class="mt-2 mb-4">
                <b-col cols="12" md="2">
                    <b-button @click="excelExport" block variant="success">{{$t('excel')}}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
//Template
//Component
import {ValidationObserver, ValidationProvider} from "vee-validate";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        MultiSelectbox,
        ParameterSelectbox
    },
    metaInfo() {
        return {
            title: this.$t("yoksis_lists"),
        };
    },
    data() {
        return {
            reportType: null,
            nationalNumber: null,
            studentStatus: null,
            justActive: 0,
            reportOptions: [
                {
                    text: this.$t('yoksis_list'),
                    value: 1,
                },
                {
                    text: this.$t('yoksis_punish_list'),
                    value: 2,
                },
                {
                    text: this.$t('yoksis_day_of_list'),
                    value: 3,
                },
                {
                    text: this.$t('yoksis_prep_detail_list'),
                    value: 4,
                },
                {
                    text: this.$t('yoksis_pedagogic_formation_list'),
                    value: 5,
                },
                {
                    text: this.$t('yoksis_other_universities_students_list'),
                    value: 6,
                },
                {
                    text: this.$t('yoksis_other_universities_active_register_students_list')+' -',
                    value: 7,
                },
                {
                    text: this.$t('yoksis_dgs_register_students_universities_list')+' -',
                    value: 8,
                },
                {
                    text: this.$t('yoksis_graduated_and_continuing_student_list')+' -',
                    value: 9,
                },
                {
                    text: this.$t('yoksis_universities')+' -',
                    value: 10,
                },
                {
                    text: this.$t('yoksis_yok_references')+' -',
                    value: 11,
                },
            ]
        }
    },
    methods: {
        async excelExport(){
            const isValid = await this.$refs.validForm.validate();
            if (isValid) {
                if(this.reportType == 1 && this.checkPermission('yoksisservice_studentlistexcelexport')){
                    let formData = {
                        student_statuses: this.studentStatus
                    }
                    YoksisService.studentList(formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(e => {
                        this.showErrors(e, this.$refs.validForm)
                    })
                } else if(this.reportType == 2 && this.checkPermission('yoksisservice_punishmentlistexcelexport')){
                    YoksisService.punishmentList(this.nationalNumber).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(e => {})
                } else if(this.reportType == 3 && this.checkPermission('yoksisservice_leaveofabsencelistexcelexport')){
                    YoksisService.leaveOfAbsenceList(this.nationalNumber).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(e => {})
                } else if(this.reportType == 4 && this.checkPermission('yoksisservice_prepdetaillistexcelexport')){
                    YoksisService.prepDetailList(this.nationalNumber).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(e => {})
                } else if(this.reportType == 5 && this.checkPermission('yoksisservice_pedagogicformationlistexcelexport')){
                    YoksisService.pedagogicList(this.nationalNumber).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(e => {})
                } else if(this.reportType == 6 && this.checkPermission('yoksisservice_otheruniversitystudentexcelexport')){
                    let formData = {
                        just_active: this.justActive
                    }
                    YoksisService.otherUniversityStudentsList(formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(e => {
                        this.showErrors(e, this.$refs.validForm)
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

            }
        }
    },
};
</script>


<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('asal_information').toUpper() }}</h5>
        <b-row>
            <b-col cols="12" md="6">
                <div class="label-as-input-div">
                    <label>{{ $t('national_id') }}</label>
                    <div>
                        {{asalData.national_id}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('name') }}</label>
                    <div>
                        {{asalData.name}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('surname') }}</label>
                    <div>
                        {{asalData.surname}}
                    </div>
                </div>
            </b-col>
            <b-col cols="12" md="6">
                <div class="label-as-input-div">
                    <label>{{ $t('military_status_description') }}</label>
                    <div>
                        {{asalData.asal_status_explanation}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('military_status_code') }}</label>
                    <div>
                        {{asalData.asal_status_code}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('military_delay_status_code') }}</label>
                    <div>
                        {{asalData.asal_delay_status_code}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('military_delay_status_description') }}</label>
                    <div>
                        {{asalData.asal_delay_status_explanation}}
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
//Template
//Component
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    props: {
        nationalId: {
            type: String,
            default: null
        }
    },
    components: {

    },
    data() {
        return {
            asalData: {}
        }
    },
    methods: {
        getData(nationalId){
            YoksisService.asalInformation(nationalId).then(response => {
                this.asalData = response.data.data
            }).catch(e => {
                this.$toast.error(e.data.message)
            })
        }
    },

    created(){
        this.getData(this.nationalId)
    }
};
</script>


<template>
    <ValidationObserver ref="filterForm">
        <b-row class="mt-4">
            <b-col cols="12" md="6">
                <ValidationProvider name="national_ids" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('national_id')">
                        <multi-text
                            v-model="form.national_ids" :only-number-and-enter="true" 
                            :height="'15em'" :validateError="errors[0]"
                        />
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12" md="6">
                <ValidationProvider name="year_count" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('year_count')">
                            <multi-selectbox 
                                :options="yearCount"
                                v-model="form.year_count"
                                :class="errors[0] ? 'is-invalid':''"
                                :multiple="false"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="12" md="2">
                <b-button @click="excelExport" block variant="success">
                    {{ $t('excel') }}
                </b-button>
            </b-col>
        </b-row>
    </ValidationObserver>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import MultiText from '@/components/interactive-fields/MultiText'
import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox'
import OsymService from '@/services/OsymService'
import qs from 'qs'

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        MultiText,
        MultiSelectbox
    },
    metaInfo() {
        return {
            title: this.$t("osym_score_information")
        }
    },
    data() {
        return {
            form: {
                national_ids: null,
                year_count: 1
            },
            yearCount: [
                {
                    value:'1', 
                    text:'1'
                }, 
                {
                    value:'2', 
                    text:'2'
                }, 
                {
                    value:'3', 
                    text:'3'
                }, 
                {
                    value:'4', 
                    text:'4'
                }, 
                {
                    value:'5', 
                    text:'5'
                }
            ]
        }
    },
    methods: {
        async excelExport() {
            if(this.checkPermission('osymservice_studentexamresultexcelexport')) {
                const isValid = await this.$refs.filterForm.validate()

                if (isValid) {
                    let config = {
                        params: {
                            national_ids: this.form.national_ids?.split(',') ?? [],
                            year_count: this.form.year_count
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false})
                    }

                    OsymService.getExamResult(config)
                        .then((response) => {
                            this.$toast.success(this.$t('api.' + response.data.message))
                            this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                        })
                        .catch((error) => {
                            let errMsg = '' 
                            Object.keys(error.data.errors).forEach((key, index) => {
                                errMsg +=  this.$t('line_number', { number: index + 1 }) + this.$t('invalid_national_id') + '\n'
                            })
                            this.$toast.error(errMsg)
                        })
                }
            } 
            else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    }
}
</script>

<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('personal_info').toUpper() }}</h5>
        <b-row>
            <b-col cols="12" md="6">
                <div class="label-as-input-div">
                    <label>{{ $t('nationality') }}</label>
                    <div>
                        {{personalData.nationality}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('national_id') }}</label>
                    <div>
                        {{personalData.national_id}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('name') }}</label>
                    <div>
                        {{personalData.name}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('surname') }}</label>
                    <div>
                        {{personalData.surname}}
                    </div>
                </div>
            </b-col>
            <b-col cols="12" md="6">
                <div class="label-as-input-div">
                    <label>{{ $t('father_name') }}</label>
                    <div>
                        {{personalData.father_name}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('gender') }}</label>
                    <div>
                        {{personalData.gender}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('birthdate') }}</label>
                    <div>
                        {{personalData.birthdate}}
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
//Template
//Component
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    props: {
        nationalId: {
            type: String,
            default: null
        }
    },
    components: {

    },
    data() {
        return {
            personalData: {}
        }
    },
    methods: {
        getData(nationalId){
            YoksisService.educationInformation(nationalId).then(response => {
                this.personalData = response.data.data[0].personal_information
            }).catch(e => {
                this.$toast.error(e.data.message)
            })
        }
    },

    created(){
        this.getData(this.nationalId)
    }
};
</script>


<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('yoksis_send_information').toUpper() }}</h5>
        <b-row>
            <b-col cols="12" md="4">
                <div class="label-as-input-div">
                    <label>{{ $t('national_id') }}</label>
                    <div>
                        {{sentData.national_id}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('unit_id') }}</label>
                    <div>
                        {{sentData.unit_id}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('input_type') }}</label>
                    <div>
                        {{sentData.registration_type}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('entry_score') }}</label>
                    <div>
                        {{sentData.registration_point}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('leaving_date') }}</label>
                    <div>
                        {{sentData.leaving_date}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('class') }}</label>
                    <div>
                        {{sentData.class}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('registration_date_just') }}</label>
                    <div>
                        {{sentData.registration_date}}
                    </div>
                </div>
            </b-col>
            <b-col cols="12" md="4">
                <div class="label-as-input-div">
                    <label>{{ $t('student_statutility') }}</label>
                    <div>
                        {{sentData.student_status}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('student_right_having') }}</label>
                    <div>
                        {{sentData.student_right}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('student_right_having_end_date') }}</label>
                    <div>
                        {{sentData.student_right_lost_date}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('active_semester_no') }}</label>
                    <div>
                        {{sentData.active_semester}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('veteran_martyr_relatives') }}</label>
                    <div>
                        {{sentData.martyr_veteran_relative}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('diploma_point') }}</label>
                    <div>
                        {{sentData.diploma_point}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('diploma_point_system') }}</label>
                    <div>
                        {{sentData.diploma_point_system}}
                    </div>
                </div>
            </b-col>
            <b-col cols="12" md="4">
                <div class="label-as-input-div">
                    <label>{{ $t('ects') }}</label>
                    <div>
                        {{sentData.ects_credit}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('prep_class_studying') }}</label>
                    <div>
                        {{sentData.prep_status}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('prep_class_studying_semesters') }}</label>
                    <div>
                        {{sentData.prep_semester_count}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('student_number') }}</label>
                    <div>
                        {{sentData.student_number}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('student_diploma_name') }}</label>
                    <div>
                        {{sentData.diploma_name}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('student_diploma_surname') }}</label>
                    <div>
                        {{sentData.diploma_surname}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('entry_score_type') }}</label>
                    <div>
                        {{sentData.registration_point_type}}
                    </div>
                </div>
            </b-col>

        </b-row>
    </div>
</template>
<script>
//Template
//Component
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    props: {
        nationalId: {
            type: String,
            default: null
        }
    },
    components: {

    },
    data() {
        return {
            sentData: {}
        }
    },
    methods: {
        getData(nationalId){
            YoksisService.sentInformation(nationalId).then(response => {
                this.sentData = response.data.data
            }).catch(e => {
                this.$toast.error(e.data.message)
            })
        }
    },

    created(){
        this.getData(this.nationalId)
    }
};
</script>


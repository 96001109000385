<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header 
                    :title="$t('yoksis_operations')" :isFilter="false"
                ></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile 
                    :title="$t('yoksis_operations')" :isFilter="false"
                ></HeaderMobile>
            </template>
            <div v-if="mainScreen == 0">
                <b-row class="mb-4">
                    <b-col cols="12" md="6">
                        <b-card bg-variant="light">
                            <b-card-text>
                                <b-row>
                                    <b-col cols="6" md="6">
                                        Son Teyitleşme Tarihi: <b>{{confirmationDate}}</b><br>
                                        Son Teyitleşme Saati: <b>{{confirmationTime}}</b>
                                    </b-col>
                                    <b-col cols="6" md="6">
                                        <b-button block variant="primary" @click="showNotifications">
                                            {{ $t('yok_notifications') }}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <b-tabs content-class="py-2 position-relative" v-model="tabIndex">
                    <div class="tabs-bottom-line"></div>
                    <b-tab>
                        <template #title>
                            <i class="ri-search-line top-plus-2"></i>
                            {{ $t('yoksis_search_student') }}
                        </template>
                        <search-student v-if="tabIndex == 0"></search-student>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <i class="ri-login-circle-line top-plus-2"></i>
                            {{ $t('yoksis_undergraduate_transfer_list') }}
                        </template>
                        <undergraduate-transfer-list v-if="tabIndex == 1"></undergraduate-transfer-list>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <i class="ri-settings-5-line top-plus-2"></i>
                            {{ $t('yoksis_multiaction') }}
                        </template>
                        <multi-actions v-if="tabIndex == 2"></multi-actions>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <i class="ri-search-eye-line top-plus-2"></i>
                            {{ $t('yoksis_student_questioning') }}
                        </template>
                        <student-questioning
                            v-if="tabIndex == 3" @showStudentInfoDetail="showStudentInfoDetail"
                        ></student-questioning>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <i class="ri-search-eye-fill top-plus-2"></i>
                            {{ $t('yoksis_meb_graduate_questioning') }}
                        </template>
                        <meb-graduate-questioning v-if="tabIndex == 4"></meb-graduate-questioning>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <i class="ri-list-check top-plus-2"></i>
                            {{ $t('yoksis_lists') }}
                        </template>
                        <lists v-if="tabIndex == 5"></lists>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <i class="ri-function-line top-plus-2"></i>
                            {{ $t('yoksis_universities') }}
                        </template>
                        <universities v-if="tabIndex == 6" @goToUnits="showUnits"></universities>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <i class="ri-file-search-line top-plus-2"></i>
                            {{ $t('university_graduate_inquiry') }}
                        </template>
                        <university-graduate-question v-if="tabIndex == 7"></university-graduate-question>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <i class="ri-file-search-line top-plus-2"></i>
                            {{ $t('osym_score_information') }}
                        </template>
                        <osym-score-information v-if="tabIndex == 8"></osym-score-information>
                    </b-tab>
                </b-tabs>
            </div>
            <yok-notifications 
                v-if="mainScreen == 1" @goBack="showMainPage"
            ></yok-notifications>
            <student-info-detail
                v-if="mainScreen == 2" :studentId="selectedStudentId" @goBack="showMainPage"
            ></student-info-detail>
            <university-units
                v-if="mainScreen == 3" :universityId="selectedUniversityId" 
                :universityName="selectedUniversityName" @goBack="showMainPage"
            ></university-units>
        </app-layout>
    </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import YokNotifications from "./YokNotifications"
import StudentInfoDetail from "./StudentInfoDetail"
import SearchStudent from "./tabs/SearchStudent"
import UndergraduateTransferList from "./tabs/UndergraduateTransferList"
import MultiActions from "./tabs/MultiActions"
import StudentQuestioning from "./tabs/StudentQuestioning"
import MebGraduateQuestioning from "./tabs/MebGraduateQuestioning"
import Lists from "./tabs/Lists"
import Universities from "./tabs/Universities"
import UniversityUnits from "./UniversityUnits"
import UniversityGraduateQuestion from "./tabs/UniversityGraduateQuestion"
import OsymScoreInformation from "./tabs/OsymScoreInformation"
import YoksisService from "@/services/YoksisService"

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        YokNotifications,
        StudentInfoDetail,
        SearchStudent,
        UndergraduateTransferList,
        MultiActions,
        StudentQuestioning,
        MebGraduateQuestioning,
        Lists,
        Universities,
        UniversityUnits,
        UniversityGraduateQuestion,
        OsymScoreInformation
    },
    metaInfo() {
        return {
            title: this.$t("yoksis_operations")
        }
    },
    data() {
        return {
            tabIndex: 0,
            mainScreen: 0,
            selectedStudentId: null,
            selectedUniversityId: null,
            selectedUniversityName: null,
            confirmationDate: '--.--.----',
            confirmationTime: '--:--:--'
        }
    },
    methods: {
        showNotifications() {
            this.mainScreen = 1
        },
        showMainPage() {
            this.mainScreen = 0
        },
        showStudentInfoDetail(id) {
            this.mainScreen = 2
            this.selectedStudentId = id
        },
        showUnits(universityId, universityName) {
            this.mainScreen = 3
            this.selectedUniversityId = universityId
            this.selectedUniversityName = universityName
        },

        getConfirmationDatetime(){
            YoksisService.getConfirmationDatetime().then(response => {
                if(response.data.data != null){
                    let dateSplit = response.data.data.split(' ')
                    this.confirmationDate = dateSplit[0].replaceAll('/','.')
                    this.confirmationTime = dateSplit[1]
                }
            }).catch(e => {
                this.showErrors(e)
            })
        }
    },
    created(){
        this.getConfirmationDatetime()
    }
}
</script>


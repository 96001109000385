<template>
    <div>
        <div class="d-flex justify-content-between mb-4">
            <b-button @click="updateUnits" variant="primary">{{$t('update_units')}}</b-button>
            <b-button @click="goBack" variant="outline-primary"><i class="ri-arrow-go-back-line"></i></b-button>
        </div>
        <div class="d-flex justify-content-between">
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
                       :title="universityName">
            </datatable>

            </div>

    </div>
</template>
<script>
//Template
//Component
import Datatable from "@/components/datatable/Datatable";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import qs from "qs";
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    props: {
        universityId: {
            type: String,
            default: null
        },
        universityName: {
            type: String,
            default: null
        }
    },
    components: {
        Datatable,
        DatatableFilter
    },
    metaInfo() {
        return {
            title: this.$t("yoksis_university_units"),
        };
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.$t('unit_name').toUpper(),
                        field: 'faculty_name',
                        hidden: false,
                        sortable: true
                    },
                    {
                        label: this.$t('unit_long_name').toUpper(),
                        field: this.getLocaleField('name'),
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('unit_type').toUpper(),
                        field: 'type_name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('unit_teach_lang').toUpper(),
                        field: 'education_language_name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('unit_teach_type').toUpper(),
                        field: 'education_type_name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('guide_code').toUpper(),
                        field: 'kodbid_code',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('unit_id').toUpper(),
                        field: 'unit_id',
                        hidden: false,
                        sortable: false
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        university_code: this.universityId
                    },
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
        }
    },
    methods: {
        goBack() {
            this.$emit('goBack', true)
        },

        filter() {
            this.getRows();
        },

        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filterSet(){
            this.datatable.queryParams.filter = {
                university_code: this.universityId
            };
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key){
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden==true ? false:true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            return YoksisService.yoksisUniversityUnits(config)
                                .then((response) => {
                                    this.datatable.rows = response.data.data
                                    this.datatable.total = response.data.pagination.total
                                 }).finally(() => {
                        this.datatable.isLoading = false;
                    });
        },
        updateUnits(){
            if(this.checkPermission('yoksisservice_yoksisuniversityandunitupdate')){
                YoksisService.yoksisUnitsUpdate().then((response) => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                    this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                }).catch(e => {
                    this.$toast.error(this.$t('api.'+e.data.message))
                })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    },
    created(){
        this.getRows()
    }
};
</script>


<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('meb_information').toUpper() }}</h5>
        <div v-for="(itm,key) in mebData" :key="key">
            <div class="mb-2 d-flex">
                <b-button variant="light"
                          class="border w-40 h-40 mr-2"
                          v-b-toggle="'collapse-'+key">
                    <i class="ri-arrow-down-s-line"></i>
                </b-button>
                <div
                    class="flex-grow-1 bg-light w-40 h-40 border rounded-sm d-flex align-items-center pl-3 font-weight-bold">
                    {{ itm.school_name }}
                </div>
            </div>
            <b-collapse :id="'collapse-'+key">
                <div class="border rounded-sm p-4 mb-4">
                    <b-row>
                        <b-col cols="auto">
                            <b-form-group :label="$t('school_name')">
                                <div class="label-as-input">
                                    {{itm.school_name}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('school_type')">
                                <div class="label-as-input">
                                    {{itm.school_type}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('school_scope')">
                                <div class="label-as-input">
                                    {{itm.school_class}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('city')">
                                <div class="label-as-input">
                                    {{itm.city}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('diploma_point')">
                                <div class="label-as-input">
                                    {{itm.diploma_point}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('graduate_date')">
                                <div class="label-as-input">
                                    {{itm.graduate_date}}
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-collapse>
        </div>
    </div>
</template>
<script>
//Template
//Component
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    props: {
        nationalId: {
            type: String,
            default: null
        }
    },
    components: {
    },
    data() {
        return {
            mebData: {}
        }
    },
    methods: {
        getData(nationalId){
            YoksisService.mebInformation(nationalId).then(response => {
                this.mebData = response.data.data
            }).catch(e => {
                this.$toast.error(e.data.message)
            })
        }
    },
    created(){
        this.getData(this.nationalId)
    }
};
</script>


<template>
    <div>
        Yök Duyurular
        <b-button @click="goBack" variant="outline-primary"><i class="ri-arrow-go-back-line"></i></b-button>
    </div>
</template>
<script>
//Template
//Component
//Other
//Service
//Page


export default {
    components: {

    },
    metaInfo() {
        return {
            title: this.$t("yok_notifications"),
        };
    },
    data() {
        return {

        }
    },
    methods: {
        goBack() {
            this.$emit('goBack', true)
        }
    },
};
</script>


<template>
    <div>
        <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
            <b-row>
                <b-col sm="6" md="4" lg="4" xl="4">
                    <b-form-group :label="$t('university')">
                        <b-form-input size="sm" v-model="datatable.queryParams.filter.name"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </datatable-filter>
        <datatable :isLoading.sync="datatable.isLoading"
                   :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total"
                   :queryParams="datatable.queryParams"
                   @on-page-change="onPageChange"
                   @on-sort-change="onSortChange"
                   @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable"
                   :title="''">
        </datatable>
    </div>
</template>
<script>
//Template
//Component
import Datatable from "@/components/datatable/Datatable";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import qs from "qs";
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    components: {
        Datatable,
        DatatableFilter,
    },
    metaInfo() {
        return {
            title: this.$t("yoksis_universities"),
        };
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons:[
                            {
                                text: this.$t('units'),
                                class: 'ri-grid-fill',
                                permission: "unitinformation_index",
                                callback:(row)=>{
                                    this.$emit('goToUnits', row.unit_id, this.getLocaleText(row, 'name'))
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('university').toUpper(),
                        field: this.getLocaleField('name'),
                        hidden: false,
                        sortable: true
                    },
                    {
                        label: this.$t('university_type').toUpper(),
                        field: 'university_type',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('city').toUpper(),
                        field: 'city',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('status').toUpper(),
                        field: 'active',
                        hidden: false,
                        sortable: false
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
        }
    },
    methods: {
        filter() {
            this.getRows();
        },

        filterClear() {
            this.filterSet()
            this.getRows();
        },

        filterSet(){
            this.datatable.queryParams.filter = {
                id: null
            };
        },

        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },

        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },

        changeColumn(key){
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden==true ? false:true;
        },

        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },

        getRows() {
            if(this.checkPermission('yoksisuniversity_index')){
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return YoksisService.yoksisUniversities(config)
                                    .then((response) => {
                                        this.datatable.rows = response.data.data
                                        this.datatable.total = response.data.pagination.total
                                    }).finally(() => {
                        this.datatable.isLoading = false;
                    })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
    },

    created() {
        this.getRows()
    }
};
</script>


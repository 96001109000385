<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('student_information').toUpper() }}</h5>
        <div v-for="(itm, key) in studentData" :key="key">
            <div class="mb-2 d-flex">
                <b-button variant="light"
                          class="border w-40 h-40 mr-2"
                          v-b-toggle="'collapse-'+key"
                >
                    <i class="ri-arrow-down-s-line"></i>
                </b-button>
                <div
                    class="flex-grow-1 bg-light w-40 h-40 border rounded-sm d-flex align-items-center pl-3 font-weight-bold">
                    {{ itm.university_name+'; '+itm.faculty+'; '+itm.program }}
                </div>
            </div>
            <b-collapse :id="'collapse-'+key">
                <div class="border rounded-sm p-4 mb-4">
                    <b-row>
                        <b-col cols="auto">
                            <b-form-group :label="$t('yoksis_student_id')">
                                <div class="label-as-input">
                                    {{itm.yoksis_student_id}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('student_number')">
                                <div class="label-as-input">
                                    {{itm.student_number}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('registration_type')">
                                <div class="label-as-input">
                                    {{itm.registration_type}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('student_right')">
                                <div class="label-as-input">
                                    {{itm.student_right}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('student_statutility')">
                                <div class="label-as-input">
                                    {{itm.student_status}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('diploma_number')">
                                <div class="label-as-input">
                                    {{itm.diploma_point}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('grade_system')">
                                <div class="label-as-input">
                                    {{itm.diploma_point_system}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('class')">
                                <div class="label-as-input">
                                    {{itm.class}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('registration_date_just')">
                                <div class="label-as-input">
                                    {{itm.registration_date}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('reason_for_leaving')">
                                <div class="label-as-input">
                                    {{itm.leaving_reason}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('working_end_date')">
                                <div class="label-as-input">
                                    {{itm.leaving_date}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('unit_id')">
                                <div class="label-as-input">
                                    {{itm.unit_id}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('ects')">
                                <div class="label-as-input">
                                    {{itm.ects_credit}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('active_semester_no')">
                                <div class="label-as-input">
                                    {{itm.active_semester}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('veteran_martyr_relatives')">
                                <div class="label-as-input">
                                    {{itm.martyr_veteran_relative}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('status')">
                                <div class="label-as-input">
                                    {{itm.status}}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="itm.allow_delete">
                            <b-button variant="danger" @click="deleteFromYoksis(itm)">{{$t('delete')}}</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-collapse>
        </div>
    </div>
</template>
<script>
//Template
//Component
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    props: {
        nationalId: {
            type: String,
            default: null
        }
    },
    components: {

    },
    data() {
        return {
            studentData: null
        }
    },
    methods: {
        getData(nationalId){
            YoksisService.educationInformation(nationalId).then(response => {
                this.studentData = response.data.data
            }).catch(e => {
                this.$toast.error(e.data.message)
            })
        },
        deleteFromYoksis(item){
            if(item.yoksis_student_id){
                YoksisService.deleteFromYoksisByYoksisId(item.yoksis_student_id, item.unit_id).then(response => {
                    this.showMessage(response);
                }).catch(e => {
                    this.$toast.error(e.data.message)
                })
            }
            else {
                YoksisService.deleteFromYoksis(this.nationalId, item.unit_id).then(response => {
                    this.showMessage(response);
                }).catch(e => {
                    this.$toast.error(e.data.message)
                })
            }
        }
    },

    created(){
        this.getData(this.nationalId)
    }
};
</script>


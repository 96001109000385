<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('kyk_information').toUpper() }}</h5>
        <b-row>
            <b-col cols="12" md="6">
                <div class="label-as-input-div">
                    <label>{{ $t('dormitory') }}</label>
                    <div>
                        {{kykData.dormitory}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('dormitory_status') }}</label>
                    <div>
                        {{kykData.dormitory_status}}
                    </div>
                </div>
            </b-col>
            <b-col cols="12" md="6">
                <div class="label-as-input-div">
                    <label>{{ $t('credit_end_date') }}</label>
                    <div>
                        {{kykData.last_credit_date}}
                    </div>
                </div>
                <div class="label-as-input-div">
                    <label>{{ $t('credit_info') }}</label>
                    <div>
                        {{kykData.credit_status}}
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
//Template
//Component
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    props: {
        nationalId: {
            type: String,
            default: null
        }
    },
    components: {

    },
    data() {
        return {
            kykData: {}
        }
    },
    methods: {
        getData(nationalId){
            YoksisService.kykInformation(nationalId).then(response => {
                this.kykData = response.data.data
            }).catch(e => {
                this.$toast.error(e.data.message)
            })
        }
    },

    created(){
        this.getData(this.nationalId)
    }
};
</script>


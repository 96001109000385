<template>
    <div>
        <ValidationObserver ref="validForm">
            <b-row class="mt-4">
                <b-col cols="12" md="6">
                    <ValidationProvider name="year" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('year')">
                            <years-selectbox
                                :interval="30"
                                v-model="formData.year"
                                :validateError="errors[0]">
                            </years-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col cols="12" md="2">
                    <b-button @click="excelExport" block variant="success">{{$t('excel')}}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
//Template
//Component
import {ValidationObserver, ValidationProvider} from "vee-validate";
import YearsSelectbox from "@/components/interactive-fields/YearsSelectbox";
//Other
//Service
import YoksisService from "@/services/YoksisService";
//Page


export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        YearsSelectbox
    },
    metaInfo() {
        return {
            title: this.$t("yoksis_undergraduate_transfer_list"),
        };
    },
    data() {
        return {
            formData: {
                year: null
            }
        }
    },
    methods: {
        async excelExport(){
            if(this.checkPermission('yoksisservice_undergraduatelistexcelexport')){
                const isValid = await this.$refs.validForm.validate();
                if (isValid) {
                    YoksisService.undergraduateListExcel(this.formData.year).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(e => {
                        this.showErrors(e, this.$refs.validForm)
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        }
    },
};
</script>


<template>
    <div>
        <ValidationObserver ref="form">
            <b-row class="mt-4">
                <b-col cols="12" md="3">
                    <ValidationProvider name="process" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('process')">
                            <parameter-selectbox
                                code="yoksis_bulk_actions"
                                v-model="formData.type"
                                :validate-error="errors[0]"
                            >
                            </parameter-selectbox>
                            <!-- <multi-selectbox
                                v-model="formData.national_numbers"
                                :options="processOptions"
                                :validateError="errors[0]">
                            </multi-selectbox> -->
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3" v-if="showStudentNumber">
                    <ValidationProvider name="student_number" :rules="isItRequired('national_id')" v-slot="{ errors }">
                        <b-form-group :label="$t('student_number')">
                            <lined-textarea
                                v-model="formData.student_number"
                                :nowrap="false"
                                :disabled="false"
                                :styles="{ height: '15em', resize: 'both' }"
                                :validateError="errors[0]"
                            >
                            </lined-textarea>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="national_number" :rules="isItRequired('student_number')" v-slot="{ errors }">
                        <b-form-group :label="$t('national_number')">
                            <lined-textarea
                                v-model="formData.national_id"
                                :nowrap="false"
                                :disabled="false"
                                :styles="{ height: '15em', resize: 'both' }"
                                :validateError="errors[0]"
                            >
                            </lined-textarea>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3" v-if="showUnitId">
                    <ValidationProvider name="unit_id" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('unit_id')">
                            <lined-textarea
                                v-model="formData.unit_id"
                                :nowrap="false"
                                :disabled="false"
                                :styles="{ height: '15em', resize: 'both' }"
                                :validateError="errors[0]"
                            >
                            </lined-textarea>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>

            <b-row class="mt-2">
                <b-col cols="12" md="2">
                    <b-button block variant="primary" @click="send">{{
                        $t("start_process")
                    }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
//Template
//Component
import { ValidationObserver, ValidationProvider } from "vee-validate";
import LinedTextarea from "@/components/elements/LinedTextarea";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
//Other
//Service
//Page
import YoksisService from "@/services/YoksisService";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        LinedTextarea,
        MultiSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t("yoksis_multiaction"),
        };
    },
    data() {
        return {
            showStudentNumber:true,
            showUnitId:false,
            formData: {},
        };
    },
    watch:{
      'formData.type'(newValue){
          if(newValue=='yoksis_delete'){
              this.showStudentNumber=false
          }else{
              this.showStudentNumber=true
          }

      }
    },
    methods: {
        async send() {
            const valid = await this.$refs["form"].validate();
            if (valid) {
                if (this.formData.student_number) {
                    this.formData.student_number =
                        this.formData.student_number.split(`\n`);
                } else {
                    delete this.formData.student_number;
                }
                if (this.formData.national_id) {
                    this.formData.national_id =
                        this.formData.national_id.split(`\n`);
                } else {
                    delete this.formData.national_id;
                }
                if (this.formData.unit_id) {
                    this.formData.unit_id =
                        this.formData.unit_id.split(`\n`);
                } else {
                    delete this.formData.unit_id;
                }
                await YoksisService.yoksisBulkAction(this.formData).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                }).catch((e) => {
                    this.showErrors(e);
                });
                this.formClear()
            }

        },
        formClear(){
            this.formData = {}
        },
        isItRequired(name) {
            return this.formData[name] ? "" : "required";
        },
    },
};
</script>
